import { Box, Stack, Theme, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  MembershipType,
  ProductPremium,
  Product,
  isProductPremiumFree,
} from "../../../../services/core-api-adapter";
import DefaultAccordion from "../../../DefaultAccordion";
import WatermarkIcon from "../../../WatermarkIcon";
import Panel from "../../../Panel";
import theme from "../../../../theme";
interface MarketplaceProductAdditionalBenefitsPanelProps {
  product: Product;
}

export default function MarketplaceProductAdditionalBenefitsPanel({
  product,
}: MarketplaceProductAdditionalBenefitsPanelProps) {
  const { t } = useTranslation();

  return (
    <>
      {product?.allowDependents ? (
        <DefaultAccordion
          title={t(
            "MarketplaceProductDetail.benefitsAdditions.accordion.title"
          )}
          aria-label={t(
            "MarketplaceProductDetail.benefitsAdditions.accordion.ariaLabel"
          )}
          icon={
            <WatermarkIcon
              extra={{
                alignSelf: "center",
                fontSize: (theme: Theme) => theme.spacing(4),
              }}
              iconSize="medium"
              color="accent1.300"
              foreground="success"
              Icon={"CheckmarkIcon"}
            />
          }
        >
          <Stack spacing={2}>
            <Typography variant="body1">
              {t(
                "MarketplaceProductDetail.benefitsAdditions.accordion.subtitle"
              )}
            </Typography>

            {product?.productPremiums
              ?.filter(
                (productDependentPremium: ProductPremium) =>
                  productDependentPremium.memberType !==
                  MembershipType.MAIN_MEMBER
              )
              .map((productDependentPremium: ProductPremium) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    key={productDependentPremium.memberType}
                    aria-label={productDependentPremium.memberType}
                  >
                    <Stack>
                      <Typography variant="h4">
                        {t(
                          `MarketplaceProductDetail.MemberType.${productDependentPremium.memberType}.title` as any
                        )}
                      </Typography>

                      {productDependentPremium.memberType !==
                        MembershipType.SPOUSE && (
                        <Typography variant="subtitle1">
                          {t(
                            `MarketplaceProductDetail.MemberType.${productDependentPremium.memberType}.subtitle` as any
                          )}
                        </Typography>
                      )}
                    </Stack>
                    <Box>
                      <Typography color="neutral.500">
                        {isProductPremiumFree(productDependentPremium) ? (
                          <Typography component="span" fontWeight={600}>
                            {t("MarketplaceProductCard.noPriceLabelText")}
                          </Typography>
                        ) : (
                          <Trans
                            i18nKey="common.pricePerMonth"
                            values={{
                              price: productDependentPremium?.premiumFormatted,
                              interval: t(
                                `PaymentInterval.${productDependentPremium?.interval}.label` as any
                              ),
                            }}
                          >
                            <Typography
                              component="span"
                              fontWeight={600}
                            ></Typography>
                          </Trans>
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                );
              })}
          </Stack>
        </DefaultAccordion>
      ) : (
        <Panel>
          <Stack direction="row" spacing={1} alignItems="center">
            <WatermarkIcon
              extra={{
                alignSelf: "center",
                fontSize: (theme: Theme) => theme.spacing(4),
              }}
              iconSize="medium"
              color={theme.palette.accent3[300]}
              foreground="error"
              Icon={"CrossIcon"}
            />
            <Typography>
              <Trans i18nKey="MarketplaceProductDetail.additions.noDependents.title"></Trans>
            </Typography>
          </Stack>
        </Panel>
      )}
    </>
  );
}
