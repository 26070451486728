import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import Panel from "../Panel";
import {
  MembershipStatus,
  Product,
  findMainMemberProductPremium,
  isProductPremiumFree,
  getProductsWithBenefitsCount,
} from "../../services/core-api-adapter";
import { useNavigate } from "react-router";
import MembershipStatusIndicator from "../MembershipStatusIndicator";
import IconLoader from "../IconLoader";
import { trackEvent } from "../../services/analytics-adapter";

interface MarketplaceProductCardProps {
  product: Product;
  isSelectedForComparison: boolean;
  toggleAddToComparison: (productID: string) => void;
  role?: string;
  isActivePlan?: boolean;
  disableComparePlan?: boolean;
}

export default function MarketplaceProductCard({
  role = "",
  product,
  isSelectedForComparison,
  disableComparePlan = true,
  isActivePlan,
  toggleAddToComparison,
}: MarketplaceProductCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainMemberProductPremium = findMainMemberProductPremium(product);
  function onToggleAddToComparisonButtonClick() {
    toggleAddToComparison(product?.id || "");
  }

  function onMoreInfoButtonClick() {
    trackEvent({
      event: "action.marketplaceProductMoreInfoViewed",
      source: "marketplace product card",
      productID: product?.id,
      productName: product?.name,
    });

    navigate(`/home/marketplace/product/${product.id}`);
  }

  return (
    <Box role={role} aria-label={product.name}>
      <Panel>
        <Stack spacing={2} justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" fontWeight={600} color="neutral.500">
              {isProductPremiumFree(mainMemberProductPremium) ? (
                <Typography
                  component="span"
                  variant="h1"
                  color={isSelectedForComparison ? "statusGreen.main" : ""}
                >
                  {t("MarketplaceProductCard.noPriceLabelText")}
                </Typography>
              ) : (
                <Trans
                  i18nKey="common.pricePerMonth"
                  values={{
                    price: mainMemberProductPremium?.premiumFormatted,
                    interval: t(
                      `PaymentInterval.${mainMemberProductPremium?.interval as any}.label` as any
                    ),
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    color={isSelectedForComparison ? "statusGreen.main" : ""}
                  ></Typography>
                </Trans>
              )}
            </Typography>
            {isActivePlan && (
              <Stack alignSelf="flex-start">
                <MembershipStatusIndicator status={MembershipStatus.ACTIVE} />
              </Stack>
            )}
          </Stack>

          <Divider />

          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h3" fontWeight={600}>
                {product.name}
              </Typography>
              <Typography variant="body2">
                {t("MarketplaceProductCard.productProvider", {
                  productProvider: product?.productProvider?.name,
                })}
              </Typography>
            </Box>
          </Stack>

          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconLoader
                icon="CheckmarkIcon"
                fontSize="small"
                color="success"
              />
              <Typography>
                <Trans
                  i18nKey="MarketplaceProductCard.productBenefits.count"
                  values={{
                    count: getProductsWithBenefitsCount(product),
                  }}
                ></Trans>
              </Typography>
            </Stack>

            {product.allowDependents ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconLoader
                  icon="CheckmarkIcon"
                  fontSize="small"
                  color="success"
                />
                <Typography>
                  <Trans i18nKey="MarketplaceProductCard.productBenefits.allowDependents"></Trans>
                </Typography>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconLoader
                  icon="CrossIcon"
                  sx={{ color: (theme) => theme.palette.grey[400] }}
                />
                <Typography sx={{ color: (theme) => theme.palette.grey[400] }}>
                  <Trans i18nKey="MarketplaceProductCard.productBenefits.noDependents"></Trans>
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent="space-between"
          >
            <Button
              size="small"
              endIcon={
                <IconLoader icon="ChevronNextIcon" sx={{ scale: "2" }} />
              }
              sx={{ flexGrow: 0 }}
              onClick={onMoreInfoButtonClick}
            >
              {t("MarketplaceProductCard.moreInfoButton")}
            </Button>

            {!isSelectedForComparison ? (
              <Button
                size="small"
                disabled={disableComparePlan}
                variant="outlined"
                startIcon={<IconLoader icon="PlusIcon" />}
                sx={{ flexGrow: 1 }}
                onClick={onToggleAddToComparisonButtonClick}
              >
                {t("MarketplaceProductCard.addToComparisonButton")}
              </Button>
            ) : (
              <Button
                size="small"
                variant="outlined"
                color="statusGreen"
                startIcon={<IconLoader icon="CheckmarkIcon" />}
                sx={{ flexGrow: 1 }}
                onClick={onToggleAddToComparisonButtonClick}
              >
                {t("MarketplaceProductCard.addedToComparisonButton")}
              </Button>
            )}
          </Stack>
        </Stack>
      </Panel>
    </Box>
  );
}
